<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">
                <ValidationObserver ref="obsClienteContacto" v-slot="{ invalid, validated}">
                    <v-form name="form" @submit.prevent="updateContacto">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Editar datos del Contacto</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar Cambios
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="8" md="8">
                                        <ValidationProvider rules="required|min:3" v-slot="{ errors, valid }" :immediate="true">
                                            <v-text-field label="Nombre del Contacto*" prepend-icon="fas fa-user" required clearable
                                            v-model="dataContacto.nombreContacto" hint="Nombre del Contacto..."
                                            :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-checkbox  class="mx-2" v-model="dataContacto.administrador" :hint="(dataContacto.administrador == false ? 'Activar ' : 'Desactivar ') +'Administrador'"
                                        persistent-hint :label="dataContacto.administrador == true ? 'Es administrador': 'No es administrador'"
                                        ></v-checkbox>
                                    </v-col>
        
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field label="Puesto del contacto" prepend-icon="fas fa-briefcase" 
                                        hint="Escriba el puesto del contacto.." required clearable v-model="dataContacto.puesto"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required|email" v-slot="{ errors, valid }" :immediate="true">
                                            <v-text-field type="email" label="Correo Electronico*" prepend-icon="fas fa-envelope" required clearable
                                            v-model="dataContacto.correo" hint="Correo Electronico..."
                                            :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required|numeric|min:10|max:12" v-slot="{ errors, valid }" :immediate="true">
                                            <v-text-field type="tel" label="Celular*" prepend-icon="fas fa-mobile" required clearable
                                            v-model="dataContacto.celular" :counter="10" hint="Número de celular..."
                                            :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <small>* Indica son campos requeridos</small>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ClienteContactoDataService from '@/service/cliente/clienteContactoDataService';
export default {
    name : "EditarContacto",
    props: {
        dialog: { default: false },
        handleModal: { type: Function },
        dataContacto: { type: Object, default: () => {} },
        initClientContacts: { type: Function }
    },
    methods: {
        updateContacto(){
            this.$refs.obsClienteContacto.validate().then( isValid => {
                if(!isValid){
                    this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', text: 'Favor de completar los campos requeridos', showConfirmButton: false, timer: 1500 });
                }
                ClienteContactoDataService.update( this.dataContacto ).then(_ => {
                    this.handleModal();
                    this.initClientContacts();
                    this.$swal.fire({ icon:'success', title: 'Excelente', text: 'Contacto modificado exitosamente', showConfirmButton: false, timer: 1500 });
                }).catch( err => {
                    this.$swal.fire({ icon: 'error', title: 'Error al actualizar el contacto', html: err.response.data, showConfirmButton: false, timer: 1500 });
                });
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Error al validar los campos', text: err.message, showConfirmButton: false, timer: 1500 });
            })
        }
    },
}
</script>