<template>
    <v-row justify="center" fullscreen>
        <v-col cols="12" md="12" sm="12">
            <v-breadcrumbs :items="pestanias">
                <template v-slot:divider>
                    <v-icon>fas fa-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
        </v-col>
        <ModalNuevoContacto :dialog.sync="dialog" :initClientContacts.sync="initClientContacts" :idCliente="idCliente" :handleModal="handleModal" v-if="dialog"/>
        <ModalEditarContacto :dialog.sync="dialogEdit" :dataContacto.sync="dataContacto" :initClientContacts.sync="initClientContacts" :handleModal="handleModalEdit" v-if="dialogEdit"/>

        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="cyan darken-4" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo contacto
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="8" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="searchContacto" append-icon="fas fa-search" label="Buscar contacto" single-inline hide-details
                        @keydown.enter="searchContacts" placeholder="Buscar por nombre, correo o teléfono">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table :headers="filteredHeaders" :items="contactos" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalContacts">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="showContact(item)" v-if="!item.eliminado">fas fa-edit</v-icon>
                        <v-icon small class="mr-2" @click="removeContact(item)" v-if="!item.eliminado">fas fa-trash-alt</v-icon>
                    </template>

                    <template v-slot:[`item.published`]="{ item }">
                        <v-chip :color="getColor(item.eliminado)" dark
                            v-text="item.eliminado == true ? 'Inactivo' : 'Activo'"></v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

    </v-row>
</template>
<script>
import ClienteContactoDataService from '@/service/cliente/clienteContactoDataService';
import ModalNuevoContacto from './NuevoContacto.vue';
import ModalEditarContacto from './EditarContacto.vue';
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';
export default {
    name: "Contactos",
    components: { ModalNuevoContacto, ModalEditarContacto, Columns },
    props: {
        idCliente: { type: String }
    },
    created() {
        this.initClientContacts();
    },
    data() {
        return {
            contactos: [],
            options: {},
            totalContacts: 0,
            loading: false,
            searchContacto: '',
            dialog: false,
            dialogEdit: false,

            dataContacto: [],
            headers: [
                { text: 'Accciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'ID', value: 'idClienteContacto', align: ' d-none', sortable: false, visible: false, groupable: false, show: false },
                { text: 'Nombre', value: 'nombreContacto', groupable: false, show: true },
                { text: 'Puesto', value: 'puesto', groupable: false, show: true },
                { text: 'Correo', value: 'correo', groupable: false, show: true },
                { text: 'Celular', value: 'celular', groupable: false, show: true },
            ],
            pestanias: [
                { text: 'Inicio', disabled: false, to: '/controlPanel' },
                { text: 'Clientes', disabled: false, to: '/controlPanel/clientes' }
            ]
        }
    },
    methods: {
        searchContacts() {
            this.options.page = 1;
            this.initClientContacts(this.options);
        },
        initClientContacts( options = {} ) {
            this.loading = true;
            const params = getParamsPagination({ options, search: this.searchContacto});
            ClienteContactoDataService.listaContactos(params, this.idCliente).then(resp => {
                const { rows, totalElements } = resp.data;
                this.contactos = rows;
                this.totalContacts = totalElements;
            }).catch(error => {
                this.$swal.fire({ title: 'Error', html: error.response.data, icon: 'error', showConfirmButton: false, timer: 1500 });
            }).finally(() => {
                this.loading = false;
            })
        },
        showContact(item) {
            this.dataContacto = item;
            this.dialogEdit = true;
        },
        removeContact(item) {
            this.$swal({
                title: 'Estas seguro?',
                text: "No se podra revertir la decision!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
            }).then((result) => {
                if (result.isConfirmed) {
                    ClienteContactoDataService.delete(item.idClienteContacto).then(_ => {
                        this.$swal.fire({ icon: 'success', title: 'Contacto eliminado correctamente', showConfirmButton: false, timer: 1500 });
                        this.initClientContacts();
                    }).catch(err => {
                        this.$swal.fire({ title: 'Cancelado', html: err.response.data, icon: 'error', timer: 1500});
                    });
                } else {
                    this.$swal.fire({ title: 'Cancelado', text: 'Solicitud cancelado', showConfirmButton: false, icon: 'info', timer: 1500 });
                }
            });
        },
        handleModal(){ this.dialog = !this.dialog; },
        handleModalEdit(){ this.dialogEdit = !this.dialogEdit; }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initClientContacts(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter( header => header.show ); }
    }
}
</script>