import { http, authHeader } from '@/http-commons';

class ClienteContactoDataService {
    create( contacto){
        return http.post(`api/v1/clienteContacto/create`, contacto, { headers : authHeader() });
    }
    listaContactos( params, idCliente ){
        return http.get(`api/v1/clienteContacto/contactos/${idCliente}`, {params, headers : authHeader() });
    }
    edit( idContacto ){
        return http.get(`api/v1/clienteContacto/edit/${idContacto}`, {headers : authHeader() });
    }
    update( contacto){
        return http.put(`api/v1/clienteContacto/update/${contacto.idClienteContacto}`, contacto, {headers: authHeader() });
    }
    delete( idContacto ){
        return http.delete(`api/v1/clienteContacto/delete/${idContacto}`, {headers : authHeader() });
    }
}
export default new ClienteContactoDataService();